import i18n from "i18next";
import { inject } from "mobx-react";
import { Component } from "react";
import type { Store } from "@inferno/renderer-shared-core";

import { Button, Spinner } from "./";
import { ButtonKind } from "./Button.component";
import "./LoadMoreFromCursor.style.scss";

export interface LoadMoreFromCursorProps<V> {
  loading: boolean;
  hasNext: boolean;
  nextFn: () => Promise<V[]>;
  store?: Store;
  sectionName?: string;
}

@inject("store")
export class LoadMoreFromCursor<V> extends Component<LoadMoreFromCursorProps<V>> {
  clickHandler = () => {
    if (this.props.store) {
      this.props.store.onAnalyticsAction.dispatch({
        sectionName: this.props.sectionName || "",
        pageName: this.props.store.page.currentPage
          ? `${this.props.store.microsite ? "microsite_" : ""}${this.props.store.page.currentPage.name}`
          : "",
        context: "load_more",
        action: "click",
      });
    }
    this.props.nextFn();
  };

  render() {
    const { loading, hasNext } = this.props;
    return hasNext ? (
      <div className="component-load-more" suppressHydrationWarning={true}>
        {loading ? (
          <Spinner visible={loading} />
        ) : (
          <Button click={this.clickHandler} kind={ButtonKind.PRIMARY}>
            {i18n.t("loadmore")}
          </Button>
        )}
      </div>
    ) : null;
  }
}

export default LoadMoreFromCursor;
